.tool-selector {
	position: fixed;
	color: white;
	z-index: 1100;
	right: 0px;
	top: 0px;
	display: grid;
	grid-template-columns: 58px 1fr;
	grid-template-rows: 100px 30px;
}
.tool-selector-left {
	height: 100px;
	background-color: #086795;
	clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
	margin-right: -1px;
}
.tool-selector-right {
	height: 100px;
	background: #086795;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 50px;
	user-select:none;
}
.tool-selector-right > div:nth-of-type(1) {
	font-size: 16px;
	padding: 10px 0 10px 0px;
	font-weight: bold;
	text-transform: uppercase;
}
.tool-selector-toggle {
	display: flex;
	flex-direction: row;
	gap: 5px;
	font-size: 12px;
	align-items: baseline;
	justify-content: center;
	user-select:none;
}
.tool-selector-toggle-transmittal:hover,.tool-selector-toggle-clipboard:hover{
	color: #ff7321;
	cursor: pointer;
}


/*****/

.tool-selector-options {
	display: flex;
	flex-direction: row;
	column-gap: 10px;
}
.tool-selector-options > div {
	width: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}
.tool-selector-options > div:hover {
	color: #ff7321;
}
.tool-selector-options > div > div:nth-of-type(1) {
	font-size: 16px;
}
.transmittal-documents-counter {
	transform: scale(0.75);
	top: -13px;
	right: 18px;
	background-color: rgb(102, 178, 84);
}
.transmittal-documents-issue {
	transform: scale(0.75);
	top: -13px;
	right: 18px;
	background-color: rgb(202, 100, 100);
}
