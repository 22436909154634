.DropdownBrowseFilter {
	user-select: none;
	display: flex;
	flex-direction: row;
	gap: 10px;
	color: #808080;
	align-items: center;
}
.DropdownBrowseFilter-disabled{
	user-select: none;
	display: flex;
	flex-direction: row;
	gap: 10px;
	color: #bdbdbd;
	cursor: no-drop;
	align-items: center;
}
.DropdownBrowseFilter-select {
	border: 1px solid #c0c1c1;
	background: white;
	padding: 5px 10px;
	border-radius: 10px;
	font-weight: bold;
	cursor: pointer;
	}
.DropdownBrowseFilter-select-disabled{
	border: 1px solid #c0c1c1;
	background: white;
	padding: 5px 10px;
	border-radius: 10px;
	font-weight: bold;
	cursor: no-drop;
}
.DropdownBrowseFilter-select > span:nth-of-type(1) {
	padding-right: 6px;
}
.DropdownBrowseFilter-select-disabled > span:nth-of-type(1) {
	padding-right: 6px;
}
.DropdownBrowseFilter-options {
	background: white;
	position: absolute;
	border: 1px solid #c0c1c1;
	cursor: pointer;
	border-radius: 4px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.DropdownBrowseFilter-icon-reset {
	cursor: pointer;
}
.DropdownBrowseFilter-icon > span:nth-of-type(2),
.DropdownBrowseFilter-icon-reset > span:nth-of-type(2) {
	padding-left: 6px;
}
.DropdownBrowseFilter-option {
	padding: 5px 50px 5px 15px;
}
.DropdownBrowseFilter-option:hover {
	background: #c0c1c1;
	color: rgb(50, 50, 50);
}
