.transmittal-info{
    margin: 25px;
    border-radius: 5px;
    display: grid;
	row-gap: 5px;
}
.transmittal-info-table{
    color: #0f334a;
	display: grid;
	grid-template-columns: 200px 1fr;
	background: white;
	grid-template-rows: minmax(50px, auto);
	align-items: center;
	padding: 0px 10px 0px 25px;
	border: 1px solid #c0c1c1;
	border-radius: 5px;
}
.transmittal-info-table > div:nth-child(2n+1){
    font-weight: bold;
    text-transform: uppercase;
}