.modal-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #00000066;
	overflow: visible;
	z-index: 5000;
}
.modal-frame {
	width: 100%;
	max-width: 900px;
	margin: 150px auto 0 auto;
	position: relative;
    max-height: calc(100vh - 210px);
    /*overflow-y: auto;*/
	overflow-y: scroll; 
	border-radius: 15px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.modal-frame-normal {
	background: white;
	padding: 25px;
}
.modal-frame-minimal{

}
.modal-close {
	position: absolute;
	cursor: pointer;
	right: 0;
	top: 0;
	padding: 15px;
}
.modal-close:hover {
	cursor: pointer;
	color: #ff7321;
}