.documents-table-transmittals {
	display: grid;
	row-gap: 5px;
}
.document-row-wrapper-transmittals {
	border: 1px solid #c0c1c1;
	border-radius: 5px;
	background: white;
}
.document-row-transmittals {
	display: grid;
	grid-template-columns: 50px 50px 1fr;
	align-items: center;
	grid-template-areas: 'document-row-trash-transmittals document-row-metadata-link document-row-metadata-transmittals';
}
.document-row-trash-transmittals {
	grid-area: document-row-trash-transmittals;
	align-items: center;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
}
.document-row-metadata-link {
	grid-area: document-row-metadata-link;
	align-items: center;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
}
.document-row-metadata-transmittals {
	grid-area: document-row-metadata-transmittals;
	display: grid;
	grid-template-columns: 1fr 120px 120px 150px;
	grid-template-rows: 20px 20px;
	padding: 10px 0px 10px 0px;
	gap: 5px 10px;
	grid-auto-flow: row;
	align-items: center;
	grid-template-areas:
		'. . . .'
		'. . . .';
}
.document-row-metadata-transmittals > div:nth-of-type(1),
.document-row-metadata-transmittals > div:nth-of-type(5) {
	padding-left: 15px;
}
.document-row-transmittals a {
	text-decoration: none;
	color: inherit;
}
.document-row-trash-transmittals,
.document-row-metadata-link,
.document-row-metadata-transmittals > div:nth-of-type(1) > a {
	cursor: pointer;
}
.document-row-trash-transmittals:hover,
.document-row-metadata-link:hover,
.document-row-metadata-transmittals > div:nth-of-type(1) > a:hover {
	color: #ff7321;
}
.document-row-metadata-transmittals > div:nth-of-type(1),
.document-row-metadata-transmittals > div:nth-of-type(2) {
	font-size: 14px;
	font-weight: bold;
}
