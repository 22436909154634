.message{
	z-index: 2000;
	position: fixed;
	top: 0px;
	left: 25%;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	padding: 25px;
	font-size: 16px;
	width: 50%;
    text-align: center;
    animation: fadeIn 1s;
}
.close-message{
    display: inline;
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
    font-weight: bold;
}
.error {
	background: rgb(202, 100, 100);
}
.alert {
	background: rgb(209, 201, 89);
}
.success {
	background: rgb(102, 178, 84);
}
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(startYposition);
	}

	100% {
		opacity: 1;
		transform: translateY(endYposition);
	}
}