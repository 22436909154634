
.tabs-menu {
	display: flex;
	justify-content: center;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
}
.tabs-menu-tab {
	padding: 5px 25px;
	color: #a1a3a3;
	cursor: pointer;
}
.tabs-menu-tab:hover {
	color: #ff7321;
}
.tabs-menu-tab-active {
	color: #004a6b;
	border-bottom: 4px solid #004a6b;
}
.tabs-menu a {
	text-decoration: none;
}