.transmittal-manager {
	position: fixed;
	color: white;
	z-index: 15;
	right: 0px;
	top: 0px;
	display: grid;
	grid-template-columns: 58px 1fr;
	grid-template-rows: 100px 30px;
}
.left {
	height: 100px;
	background-color: #086795;
	clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
	margin-right: -1px;
}
.right {
	height: 100px;
	background: #086795;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 50px;
}
.right > div:nth-of-type(1) {
	font-size: 16px;
	padding: 10px 0 10px 0px;
	font-weight: bold;
	text-transform: uppercase;
}
.transmittal-manager-options {
	display: flex;
	flex-direction: row;
	column-gap: 10px;
}
.transmittal-manager-options > div {
	width: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}
.transmittal-manager-options > div:hover {
	color: #ff7321;
}
.transmittal-manager-options > div > div:nth-of-type(1) {
	font-size: 16px;
}
.transmittal-documents-counter {
	transform: scale(0.75);
	top: -13px;
	right: 18px;
	background-color: rgb(102, 178, 84);
}
.transmittal-documents-issue {
	transform: scale(0.75);
	top: -13px;
	right: 18px;
	background-color: rgb(202, 100, 100);
}
.toggle-transmittal-clipboard {
	display: flex;
	flex-direction: row;
	gap: 5px;
	font-size: 12px;
	align-items: baseline;
	justify-content: center;
}
.toggle-transmittal:hover,.toggle-clipboard:hover{
	color: #ff7321;
	cursor: pointer;
}
