@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');*/
@font-face {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 300;
	src: local('Titillium WebLight'), local('TitilliumWeb-Light'),
		url(https://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff) format('woff');
}
*::-webkit-scrollbar {
	width: 5px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
	background: #004a6b; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
	background-color: #086795; /* color of the scroll thumb */
	border-radius: 20px; /* roundness of the scroll thumb */
	border: 3px solid #ff7321; /* creates padding around scroll thumb */
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

::before,
::after {
	box-sizing: inherit;
}

* {
	box-sizing: border-box;
}

.login-in-process {
	text-align: center;
	font-size: 40px;
	background: #004a6b;
	display: flex;
	height: 100vh;
	color: white;
	width: 100%;
	align-items: center;
	justify-content: center;
}
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Titillium Web', sans-serif;
	/*font-family: 'Inter', sans-serif;*/
	background: #e9e9e9;
	/*color: #004a6b;*/
	color: #0f334a;
	font-size: 12px;
}
mark {
	background-color: #086795;
	color: white;
}
.mt-25 {
	margin-top: 25px;
}
.app {
	display: grid;
	height: 100vh;
	grid-template-columns: minmax(50px, auto) 1fr;
	grid-template-rows: min-content 1fr;
}

.app-header {
	background: #0f334a;
	grid-column: 1 / span 2;
	color: white;
	position: sticky;
	top: 0;
	z-index: 1000;
}
.app-heading {
	grid-area: heading;
	background: rgb(15, 51, 74);
	display: flex;
	align-items: center;
	height: 100px;
}
.app-heading-logo {
	height: 50px;
	content: url('/src/resources/wartsila-logo-white.svg');
	margin: 25px; /* If you want no margin */
	padding: 0; /*if your want to padding */
}
.app-heading-name {
	flex-grow: 1;
	height: 180px;
	/*content: url('/src/resources/Sales-DCM-White.svg');*/
	content: url('/src/resources/Slime-Sales-DCM-White.svg');
	text-align: center;
}
.app-sidebar {
	background: #004a6b;
	overflow: scroll;
}
.app-content {
	overflow: visible;
}
.app-topbar {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	background: #086795;
}
.breadcrumbs {
	padding-left: 25px;

	height: 30px;
}

.breadcrumb {
	display: inline-block;
	padding: 5px 3px;
}
.breadcrumb a {
	color: #ededed;
	text-decoration: none;
}
.breadcrumb a:hover {
	color: #ff7321;
}
.sidebar {
	padding: 25px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	color: #ededed;
}
.toggle-tree {
	display: inline;
	cursor: pointer;
	margin-bottom: 5px;
}
.toggle-tree:hover {
	color: #ff7321;
}
.toggle-tree > span:nth-of-type(1) {
	padding-right: 5px;
}
.sidebar-structure ul {
	list-style: none;
	padding-left: 0;
	margin: 0px 0px 0px 28px;
	line-height: 25px;
}
.sidebar-structure ul li {
	font-size: 12px;
	position: relative;
}
.sidebar-structure ul li:before {
	position: absolute;
	left: -17px;
	top: 0px;
	content: '';
	display: block;
	border-left: 1px solid #ddd;
	height: 14px;
	border-bottom: 1px solid #ddd;
	width: 15px;
}
.sidebar-structure ul li:after {
	position: absolute;
	left: -17px;
	bottom: -7px;
	content: '';
	display: block;
	border-left: 1px solid #ddd;
	height: 100%;
}
.sidebar-structure ul li span {
	padding-right: 5px;
	cursor: pointer;
}
.sidebar-structure li a {
	text-decoration: none;
	color: inherit;
}
.sidebar-structure ul li div {
	padding-left: 5px;
	padding-right: 6px;
	display: inline-block;
	border-radius: 5px;
}
.sidebar-structure ul li div:hover {
	background: #e9e9e9;
	color: rgb(15, 51, 74);
}
.node-selected {
	color: #ff7321;
	font-weight: bold;
}
.page {
	margin: 25px;
}
.btn {
	font-family: 'Titillium Web', sans-serif;
	font: inherit;
	padding: 5px 10px;
	color: white;
	font-size: 12px;
	border-radius: 5px;
	cursor: pointer;
	user-select: none;
	border: none;
	outline: inherit;
	display: block;
}
.btn-full-width{
	width: 100%;
}
.btn-flat {
	display: inline;
	margin: 0px 10px 0px 0px;
	cursor: pointer;
}
.btn-flat > span:nth-of-type(1) {
	padding-right: 5px;
}
.btn > span:nth-of-type(1) {
	padding-right: 5px;
}
.btn-flat-blue {
	color: rgb(0, 65, 97);
}
.btn-flat-blue:hover {
	color: #ff7321;
}
.btn:disabled,
.btn[disabled] {
	background: #c0c1c1;
}
.btn-blue {
	background: rgb(8, 103, 149);
}
.btn-blue:hover {
	background: rgb(0, 65, 97);
}
.btn:disabled:hover,
.btn[disabled]:hover {
	background: #c0c1c1;
	cursor: not-allowed;
}
.subpage-heading {
	background: white;
	padding: 25px;
	border-bottom: 1px solid #c0c1c1;
}
.subpage-heading > div:first-of-type span,
.subpage-heading-document span {
	padding-left: 15px;
}

.rename-folder {
	display: flex;
	align-items: center;
	column-gap: 15px;
}
.documents-table > div:first-of-type {
	padding: 0px 0px 10px 0px !important;
}
.documents-table > div:not(.document-row-wrapper) {
	border-bottom: 1px solid #c0c1c1;
	padding: 10px 0px;
}
.document-transmittals {
	display: flex;
	align-items: center;
	gap: 10px;
	color: #808080;
}
.badge {
	border-radius: 10px;
	color: #0f334a;
	padding: 5px 10px;
	border: 1px solid #c0c1c1;
}
.badge > span {
	font-weight: bold;
	padding-right: 5px;
}

.documents-toggle {
	user-select: none;
}
.documents-toggle-component {
	font-size: 14px;
	color: #808080;
	cursor: pointer;
}
.documents-toggle-component:hover {
	color: #ff7321;
}
.documents-toggle-component > span {
	padding-right: 20px;
}
.document-upload-wrapper {
	border: 2px dashed #c0c1c1;
	border-radius: 5px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 40px;
	background: white;
	color: #086795;
}
.documents-table {
	display: grid;
	row-gap: 5px;
}
.document-filter {
	display: grid;
	grid-template-columns: max-content max-content max-content max-content 1fr;
	column-gap: 25px;
}
.filter-select {
	border: 1px solid #c0c1c1;
	background: white;
	padding: 5px 10px;
	border-radius: 10px;
	font-weight: bold;
}
.filter-search-container {
	display: flex;
	flex-direction: row;
	gap: 10px;
	color: #808080;
	align-items: center;
	justify-content: end;
}
.filter-search {
	background: white;
	border-radius: 40px;
	border: 1px solid #c0c1c1;
	cursor: pointer;
}

.filter-search > input {
	box-sizing: border-box;
	background: none;
	padding: 0px;
	margin: 0px;
	border-radius: 0px;
	height: 36px;
	color: #808080;
	outline: none;
	border: none;
	line-height: 20px;
	width: 0px;
	float: left;
	transition: 0.7s ease;
}

.filter-search > span {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	background: #c0c1c1;
	color: #353535;
	padding: 12px;
	border-radius: 50%;
	float: right;
	transition: 0.4s ease;
}

.filter-search:hover > input,
.filter-search > input:focus {
	width: 240px;
	margin: 0px 8px;
	padding-left: 5px;
}

.filter-search:hover > span,
.search-input:focus + span {
	background: #353535;
	color: #c0c1c1;
}

.document-sort {
	user-select: none;
	display: grid;
	grid-template-columns: 50px 1fr 50px;
	color: #808080;
	align-items: center;
	font-size: 14px;
	grid-template-areas: 'document-sort-checkbox document-sort-metadata document-sort-menu';
}
.document-sort-checkbox {
	grid-area: document-sort-checkbox;
	align-items: center;
	font-size: 16px;
	text-align: center;
}
.document-sort-metadata {
	grid-area: document-sort-metadata;
	display: grid;
	grid-template-columns: 70px 1fr 120px 150px 150px;
	grid-template-rows: 20px 20px;

	gap: 5px 10px;
	grid-auto-flow: row;
	align-items: center;
	grid-template-areas:
		'. . . . .'
		'. . . . .';
}
.sort-column {
	cursor: pointer;
	font-weight: bold;
}
.sort-column > span {
	padding-left: 5px;
}
.sort-column:hover {
	color: #ff7321;
}

/**********************************************************/
.document-row-wrapper {
	border: 1px solid #c0c1c1;
	border-radius: 5px;
	background: white;
}

.old-revision {
	font-style: italic;
	color: #086795;
	background: #f9f7f7;
	border-top: 1px solid #c0c1c1;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

/* filtered === true --> Should be hided */
.document-filtered-true {
	background: #ededed;
	border-radius: 5px;
}
.document-row {
	display: grid;
	grid-template-columns: 50px 1fr 50px;
	align-items: center;
	grid-template-areas: 'document-row-checkbox document-row-metadata document-row-menu';
}

.document-row-checkbox {
	grid-area: document-row-checkbox;
	align-items: center;
	font-size: 16px;
	text-align: center;
	position: relative;
	cursor: pointer;
}
.document-in-transmittal {
	user-select: none;
	position: absolute;
	top: -27px;
	font-size: 26px;
	color: #ff7321;
	right: 39px;
	/*transform: scale(-1,1);*/
}
.document-row-metadata {
	grid-area: document-row-metadata;
	display: grid;
	grid-template-columns: 70px 1fr 120px 150px 150px;
	grid-template-rows: 20px 20px;
	padding: 10px 0px 10px 0px;
	gap: 5px 10px;
	grid-auto-flow: row;
	align-items: center;
	grid-template-areas:
		'. . . . .'
		'. . . . .';
}
.document-row a {
	text-decoration: none;
	color: inherit;
}
.document-row-metadata > div:nth-of-type(1),
.document-row-metadata > div:nth-of-type(6),
.document-row-metadata > div:nth-of-type(2) > a {
	cursor: pointer;
}
.document-row-metadata > div:nth-of-type(1):hover,
.document-row-metadata > div:nth-of-type(6):hover,
.document-row-metadata > div:nth-of-type(2) > a:hover {
	color: #ff7321;
}
.document-row-metadata > div:nth-of-type(1),
.document-row-metadata > div:nth-of-type(2),
.document-row-metadata > div:nth-of-type(3),
.document-row-metadata > div:nth-of-type(6) {
	font-size: 14px;
}
.document-row-metadata > div:nth-of-type(2),
.document-row-metadata > div:nth-of-type(3) {
	font-weight: bold;
}
.document-row-menu {
	grid-area: document-row-menu;
	font-size: 24px;
	cursor: pointer;
	text-align: center;
	color: #808080;
}

.doc-properties {
	display: grid;
	row-gap: 5px;
}
.doc-properties-row {
	color: #0f334a;
	display: grid;
	grid-template-columns: 200px 1fr;
	background: white;
	grid-template-rows: minmax(50px, auto);
	align-items: center;
	padding: 0px 10px 0px 25px;
	border: 1px solid #c0c1c1;
	border-radius: 5px;
}
.doc-properties-row > div:nth-of-type(1) {
	font-weight: bold;
	text-transform: uppercase;
	padding-right: 15px;
}
.required:after {
	content: ' *';
	color: red;
	font-weight: bold;
}
.doc-properties-row-with-info {
	display: flex;
	justify-content: space-between;
}
.doc-properties-row-file-link {
	cursor: pointer;
}
.doc-properties-row-file-link:hover {
	color: #ff7321;
}
.input {
	font-family: 'Titillium Web';
	box-sizing: border-box;
	border: 1px solid #c0c1c1;
	padding: 9px;
	border-radius: 5px;
	width: 100%;
	font-size: 12px;
	outline: none;
}
.textarea {
	padding: 9px;
	margin: 5px 0px;	
	outline: none;
	width: 100%;
	font-family: inherit;
	resize: none;
	overflow: auto;
	border: 1px solid #c0c1c1;
	border-radius: 5px;
}
/*
input {
	box-sizing: border-box;
	border: 1px solid #c0c1c1;
	padding: 10px;
	border-radius: 5px;
	background: #ededed;
}
*/
.skeleton-loading {
	background: white;
}
.skeleton {
	background: #dbdbdb;
	background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
	border-radius: 5px;
	background-size: 200% 100%;
	animation: 1.5s shine linear infinite;
	display: inline-block;
	border-radius: 4px;
}
.skeleton-loading-width-60 {
	width: 60%;
	height: 12px;
}
.skeleton-loading-width-30 {
	width: 30%;
	height: 12px;
}
.skeleton-loading-menu-icon {
	cursor: not-allowed;
}
.skeleton-loading-doc-icons {
	width: 37px;
	height: 10px;
}
@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

.subpage-heading-document {
	background: white;
	padding: 25px 25px 0px 25px;
	border-bottom: 1px solid #c0c1c1;
}
.inside-document-upload-wrapper {
	border: 1px solid #086795;
	color: #fff;
	background: #086795;
}
.file-to-Upload {
	display: grid;
	grid-template-columns: 70px 1fr;
	margin-top: 5px;
	border: 1px solid #c0c1c1;
	border-radius: 5px;
	background: white;
	padding: 5px 0px 5px 0px;
}
.file-to-Upload-col-2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.file-to-Upload-col-2 > div:nth-of-type(1) {
	font-size: 25px;
}
.file-to-Upload-col-3 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 15px;
	margin-right: 15px;
}
.file-to-Upload-col-3 > div:nth-of-type(1) {
	font-size: 14px;
	color: #0f334a;
	font-weight: bold;
	display: flex;
	gap: 10px;
	align-items: center;
}
.file-to-Upload-col-3 > div:nth-of-type(1) span {
	cursor: pointer;
	padding-right: 5px;
}
.file-to-Upload-col-3 > div:nth-of-type(1) span:hover {
	color: #ff7321;
}
.file-to-Upload-col-3 > div:nth-of-type(2) span {
	cursor: pointer;
}
.file-to-Upload-col-3 > div:nth-of-type(2) span:hover {
	color: #ff7321;
}
.file-to-Upload-col-1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.progress-bar {
	height: 4px;
	background-color: rgb(8 103 149 / 25%);
	width: 100%;
	overflow: hidden;
}

.progress-bar-value {
	width: 100%;
	height: 100%;
	background-color: rgb(8, 103, 149);
	animation: indeterminateAnimation 1s infinite linear;
	transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
	0% {
		transform: translateX(0) scaleX(0);
	}
	40% {
		transform: translateX(0) scaleX(0.4);
	}
	100% {
		transform: translateX(100%) scaleX(0.5);
	}
}
.transmittals-list-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 25px;
}
.transmittals-list {
	color: #0f334a;
	background: white;
	border: 1px solid #c0c1c1;
	border-radius: 5px;
}
.transmittal-list-row {
	display: grid;
	padding: 10px;
	grid-template-columns: 30px 70px 1fr 100px 130px 170px;
	row-gap: 10px;
}

.transmittals-list > div:first-of-type {
	font-weight: bold;
	text-transform: uppercase;
}
.transmittal-list-row:not(:first-of-type) > div:nth-of-type(3){
	font-weight: bold;
}
.transmittal-list-row:not(:first-of-type) > div:last-of-type{
	grid-column: 3 / span 3;
	font-style: italic;
}
.transmittal-list-row:not(:last-of-type) {
	border-bottom: 1px solid #c0c1c1;
}
.transmittal-list-row:not(:first-of-type) > div:nth-of-type(1) {
	cursor: pointer;
	grid-row: 1 / span 2;
	text-align: center;
	display: flex;
	font-size: 16px;
	justify-content: center;
	align-items: center;
}
.transmittal-list-row:not(:first-of-type) > div:nth-of-type(2) {
	cursor: pointer;
	grid-row: 1 / span 2;
	display: flex;
	justify-content: center;
	align-items: center;
}
.transmittal-list-row:not(:first-of-type) > div:nth-of-type(2)> a {
	text-decoration: none;
	color: #ff7321;
	font-weight: bold;
	font-size: 16px;
}
.table {
	color: #0f334a;
	background: white;
	border: 1px solid #c0c1c1;
	border-radius: 5px;
	margin-bottom: 20px;
}
.table-row,
.table-heading {
	display: grid;
	padding: 10px;
	row-gap: 10px;
	column-gap: 5px;
}
.table-heading {
	font-weight: bold;
	text-transform: uppercase;
}
.table-row:not(:last-of-type) {
	border-bottom: 1px solid #c0c1c1;
}
.documents-cant-transmit {
	grid-template-columns: 1fr 90px 90px 90px 1fr;
}
.documents-in-cliboard {
	grid-template-columns: 40px 1fr 90px 40px 90px 55px;
	row-gap: 0px;
}
.documents-in-cliboard:not(:first-of-type) > div:nth-of-type(1) {
	grid-row: 1 / span 2;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
}
.documents-in-cliboard-source-path {
	grid-column: 2 / span 4;
	color: #c0c1c1;
	font-style: italic;
	cursor: pointer;
}

.documents-in-cliboard:not(:first-of-type) > div:nth-of-type(2){
	font-weight: bold;
	cursor: pointer;
}
.documents-in-cliboard:not(:first-of-type) > div:nth-of-type(1),
.documents-in-cliboard:not(:first-of-type) > div:nth-of-type(6) {
	cursor: pointer;
	text-align: center;
}
.documents-in-cliboard:not(:first-of-type) > div:nth-of-type(6):hover,
.documents-in-cliboard:not(:first-of-type) > div:nth-of-type(2):hover,
.documents-in-cliboard-source-path:hover {
	color: #ff7321;
}
.copy-move-clipboard-table {
	grid-template-columns: 95px 30px 1fr;
	align-items: baseline;
}
.copy-move-clipboard-table > div:nth-of-type(2) {
	text-align: center;
}
.copy-move-clipboard-table > div:nth-of-type(3) {
	font-weight: bold;
}
.upload-pdf-render-table {
	grid-template-columns: 1fr 30px;
}
.upload-pdf-render-table > div:nth-of-type(2) {
	cursor: pointer;
}
.upload-pdf-render-table > div:nth-of-type(2):hover {
	color: #ff7321;
}
.template-documents {
	grid-template-columns: 12px 20px 2fr 40px;
	row-gap: 0px;
}