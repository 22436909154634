.comment-section{
	margin: 25px;
}
.RevisionGroup {
padding-bottom: 5px;
}
.comment {
	background: rgb(249, 249, 249);
	padding: 10px;
	font-size: 12px;
	color: rgb(15, 51, 74);
	border-radius: 5px;
	font-style: normal;
	border: 1px solid #c0c1c1;
	max-width: 600px;
	margin-bottom: 10px;
}
.comment .comment{
	background: white;
}
.reply{
	margin-top: 5px;
	background: white;
}
.comment > div:nth-of-type(1) {
	padding: 5px;
	display: flex;
	justify-content: space-between;
}
.comment > div:nth-of-type(1) > div:nth-of-type(1) > span:nth-of-type(1) {
	margin-right: 5px;
	font-size: 14px;
	font-weight: bold;
}
.comment > div:nth-of-type(1) > div:nth-of-type(1) > span:nth-of-type(2) {
	font-size: 14px;
	font-weight: bold;
}
.comment > div:nth-of-type(1) > div:nth-of-type(1) > span:nth-of-type(3), .comment > div:nth-of-type(1) > div:nth-of-type(1) > span:nth-of-type(4) {
	margin-left:10px;
	font-size: 10px;
	font-style: italic;
	font-weight: normal;
}
.comment > div:nth-of-type(1) > div:nth-of-type(2) {
	cursor: pointer;
	color: #086795;
	font-weight: bold;
}
.comment > div:nth-of-type(1) > div:nth-of-type(2):hover {
	color: #ff7321;
}
.comment > div:nth-of-type(1) > div:nth-of-type(2) > span:nth-of-type(1) {
	margin-right: 5px;
}
.comment > div:nth-of-type(2) > div {
	border-top: 1px solid #c0c1c1;
	padding:5px;
}
.comment > span:nth-of-type(1) {
	display: inline;
	margin: 5px;
    cursor: pointer;
}
.comment > span:nth-of-type(1):hover {
    color: #ff7321;
}
.comment > span:nth-of-type(1) > span:nth-of-type(1) {
    padding-right: 5px;
}

.comment-textarea {
	padding: 5px;
	outline: none;
	width: 100%;
	font-family: inherit;
	resize: none;
	overflow: auto;
	border: 1px solid #c0c1c1;
}
