.email-picker-wrapper{
    border: 1px solid gray;
    height: 200px;
    margin: 25px;
    padding: 25px;
}

.email-picker-badge{
    background: gray;
    color: white;
    padding: 5px;
    display: inline;
    font-size: 20px;
    margin-right: 20px;
}
.email-picker-badge > span{
    margin: 10px;
}
.email-picker-wrapper input{
    border: 0;
    
    outline: none;
    display: inline;
}