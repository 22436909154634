.inplace-editor{


}
.inplace-editor-icon-wrapper{
    
}
.inplace-editor-icon{
    cursor: pointer;
    padding: 5px;
    font-size: 14px;
}

.inplace-editor-buttons{
    display: flex;
    position: absolute;
    column-gap: 5px;
    background: white;
    padding: 5px;
    border-right: 1px solid #c0c1c1;
    border-left: 1px solid #c0c1c1;
    border-bottom: 1px solid #c0c1c1;
    border-bottom-left-radius : 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    z-index: 1000;
}
.inplace-editor-buttons > div{
    background: #e9e9e9;
    border: 1px solid #c0c1c1;
	border-radius: 5px;
    width: 30px;
    height: 30px;
    text-align: center;
}
.inplace-editor-buttons > div:hover{
    background: #c0c1c1;   
}

