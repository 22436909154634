.asdsa{
    color: #0f334a;
	background: white;
	padding: 0px 15px 0px 15px;
	border: 1px solid #c0c1c1;
	border-radius: 5px;
}
.RevisionGroup {
	display: flex;
	justify-content: space-between;
    margin:10px 15px;
}
.RevisionGroup span:nth-of-type(2) {
	cursor: pointer;
}
.RevisionGroup span:nth-of-type(2):hover {
	color: #ff7321;
}
.version {
	font-size: 12px;
	margin: 10px;
	padding: 10px;
    color: #0f334a;
	background: #e9e9e9;
	border: 1px solid #c0c1c1;
	border-radius: 5px;
}
.version > div:nth-of-type(1),
.version > div:nth-of-type(2),
.version > div:nth-of-type(3) {
	display: grid;
	grid-template-columns: 90px max-content;
	margin: 5px;
    
}
.version > div:nth-of-type(1) > span:nth-of-type(1) {
	text-transform: uppercase;
	font-weight: bold;
}
.version > div:nth-of-type(2) > span:nth-of-type(1) {
	text-transform: uppercase;
	font-weight: bold;
}
.version > div:nth-of-type(3) > span:nth-of-type(1) {
	text-transform: uppercase;
	font-weight: bold;
}
.version-table {
	background: #f7f7f7;
	display: grid;
	grid-template-columns: 200px max-content min-content max-content;
	column-gap: 20px;
	margin: 5px;
	padding: 5px;
    border: 1px solid #c0c1c1;
    border-radius: 5px;
}
.version-table > div:nth-of-type(1) {
	margin-left: 5px;
	font-weight: bold;
	text-transform: uppercase;
}
.version-table > div:nth-of-type(2) {
}
