.dropdown {
	user-select: none;
	width: 100%;
	border-radius: 5px;
	background-color: white;
	border: 1px solid #c0c1c1;
	position: relative;
}

.dropdown-header {
	cursor: pointer;
	display: grid;
	grid-template-columns: 1fr 20px;
	grid-template-rows: 38px;
	justify-content: space-between;
	align-items: center;
	padding-left: 15px;

}
.dropdown-header > input {
	font-family: 'Titillium Web', sans-serif;
	color: #0f334a;
	font-size: 12px;
	width: 100%;
	border: none;
	height: 12px;
	padding: 15px 15px 15px 0px;
	outline:none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.dropdown-selected-item{
	position: absolute;
}
.dropdown-selected-item > span > span{
	padding-right: 5px;
	color: #a1a1a1;
}
.dropdown-body {
	padding: 0px;
	z-index: 1;
	display: none;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid #c0c1c1;
    position: absolute;
    background: white;
    width: 100%;
	max-height: 250px;
	overflow: auto;
}

.dropdown-body.open {
	display: block;
}
.dropdown-group{
	padding-left: 15px;
}
.dropdown-group-header{
	padding: 10px 25px;
	font-weight: bold;
}
.dropdown-item {
	display: grid;
	grid-template-columns: 1fr 40px;
}
.dropdown-item > div:first-of-type {
	padding: 10px 0px;
	display: grid;
	grid-template-columns: 40px 1fr;
}
.dropdown-item > div:first-of-type > div:first-of-type {
	text-align: center;
}

.dropdown-item > div:last-of-type {
	padding: 10px 0px;
	text-align: center;
}
.dropdown-item:hover {
	cursor: pointer;
	
	font-weight: bold;
}

.dropdown-item-dot {
	opacity: 0;
	color: #91a5be;
	transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
	opacity: 1;
}

.icon {
	font-size: 13px;
	color: #91a5be;
	transform: rotate(0deg);
	transition: all 0.2s ease-in-out;
}

.icon.open {
	transform: rotate(90deg);
}