.tooltip {
	position: relative;
	display: inline-block;
	cursor: pointer;
	overflow: visible;
	
}
.tooltip .tooltiptext {
	overflow: visible;
	visibility: hidden;
	width:250px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 10px;
	position: absolute;
	z-index: 1000;
	bottom: 125%;
	left: 50%;
	margin-left: -125px;
	opacity: 0;
	transition: opacity 0.3s;
	box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
	font-style: normal;
	text-transform: none;
	font-weight: normal;
}

.tooltip .tooltiptext::after {
	content: '';
	position: absolute;
	top: 100%;
	z-index: 1000;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
	font-size: 12px;
}
