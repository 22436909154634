
.document-row-menu-wrapper{
    position: relative;
}

.document-row-menu-options{
    background: white;
    z-index: 1;
    position: absolute;
    border: 1px solid #c0c1c1;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    right: 30px;
    top:17px;
    color: #808080;
}
.document-row-menu-option{
    padding: 5px 50px 5px 15px;
    white-space: nowrap;

 
}

.document-row-menu-option:hover{
    background: #c0c1c1;
    color:rgb(50,50,50);
}